import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HELM from "../components/helmet.js"
import Layout from "../components/layout/layout.js"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"
import aboutStyles from "../utilities/pageStyles/about.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

export default function About() {
  const aboutQuery = useStaticQuery(graphql`
    query {
      ourBrand: allContentfulOurBrand {
        edges {
          node {
            image {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            heading
            subHeading
            description {
              json
            }
          }
        }
      }
      ourTeam: allContentfulOurTeam {
        edges {
          node {
            image {
              fluid(maxWidth: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            name
            role
            facebook
            instagram
            twitter
          }
        }
      }
    }
  `)

  const ourBrandData = aboutQuery.ourBrand.edges
  const ourTeamData = aboutQuery.ourTeam.edges

  const options = {
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }

  const renderOurBrand = () => {
    return ourBrandData.map(({ node }, i) => {
      return (
        <div className={aboutStyles.ourBrand}>
          <div
            key={`our-brand-${node.heading}-i`}
            className={aboutStyles.ourBrandImage}
            style={{ gridArea: i % 2 === 0 ? "left" : "right" }}
          >
            <Img alt="Lilly Feather's Story" fluid={node.image.fluid} />
          </div>
          <div
            className={aboutStyles.ourBrandText}
            style={{ gridArea: i % 2 === 0 ? "right" : "left" }}
          >
            <h2 className={aboutStyles.ourBrandHeading}>{node.heading}</h2>
            <h2 className={aboutStyles.ourBrandSubHeading}>
              {node.subHeading}
            </h2>
            <div className={aboutStyles.ourBrandDescription}>
              {documentToReactComponents(node.description.json, options)}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderOurTeam = () => {
    return ourTeamData.map(({ node }, i) => {
      return (
        <div
          key={`team-${node.name}-${i}`}
          className={aboutStyles.teamMemberCard}
        >
          <div className={aboutStyles.teamMemberImage}>
            <Img alt={node.name} fluid={node.image.fluid} />
          </div>
          <div className={aboutStyles.teamMemberText}>
            <span className={aboutStyles.teamMemberName}>{node.name}</span>
            <span className={aboutStyles.teamMemberRole}>{node.role}</span>
          </div>
          <div className={aboutStyles.teamMemberSocial}>
            {node.facebook === "#" ? null : (
              <a
                href={node.facebook}
                rel="noreferrer"
                target="__blank"
                className={aboutStyles.teamMemberSocialIcon}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            )}
            {node.instagram === "#" ? null : (
              <a
                href={node.instagram}
                rel="noreferrer"
                target="__blank"
                className={aboutStyles.teamMemberSocialIcon}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            )}
            {node.twitter === "#" ? null : (
              <a
                href={node.twitter}
                rel="noreferrer"
                target="__blank"
                className={aboutStyles.teamMemberSocialIcon}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            )}
          </div>
        </div>
      )
    })
  }

  return (
    <Layout>
      <HELM
        title="About Us"
        keywords={[`Lilly Feather`, `Filipina`, `Clothing`, `Story`, `Our Brand`, `Branding`]}
      />
      <div className={aboutStyles.container}>
        <h1 className={aboutStyles.heading}>We are Lilly Feather</h1>
        <div className={aboutStyles.ourBrandContainer}>
          <div className={aboutStyles.shadows}>
            <h1 className={aboutStyles.shadowOur}>Our</h1>
            <h1 className={aboutStyles.shadowBrand}>Story</h1>
          </div>
          {renderOurBrand()}
        </div>
        <h2 className={aboutStyles.teamHeading}>
          The Faces behind Lilly Feather
        </h2>
        <div className={aboutStyles.team}>{renderOurTeam()}</div>
      </div>
    </Layout>
  )
}
